<template>

<app-page>

	<app-page-head :title="title" :back="($route.query.group) ? 'Group.Items' : 'Items'" :params="($route.query.group) ? {group: $route.query.group} : false">

		<app-page-head-action :disabled="!isDirty" icon="undo" tooltip="Undo changes" v-on:click.native="onUndoClick" />
		<app-page-head-action :loading="is.saving" :disabled="!isDirty" icon="save" tooltip="Save changes" v-on:click.native="onSaveClick" />
		<app-page-head-action route="Item.Delete" v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" tooltip="Delete" />
		
	</app-page-head>

	<app-page-content :is-grown="true">

		<app-page-content-section :is-padded="true">

			<app-input-text v-model="model.title" :validation="$v.model.title" label="Title" placeholder="Enter title" :maxlength="64" />
			<app-input-suggest v-model="model.group" :validation="$v.model.group" store="groups" image="image" :show-all="true" label="Group" placeholder="Select group" />
			<app-input-slug v-model="model.slug" :validation="$v.model.slug" :source="model.title" label="Slug" placeholder="Enter slug" :maxlength="64" />
			<app-input-text v-model="model.text" :autogrow="true" label="Description" placeholder="Enter text" />
			<app-input-image v-model="model.image" :validation="$v.model.image" label="Image" />
			<app-input-toggle v-model="model.visible" label="Visible" />
			<app-input-toggle v-model="model.exclude" label="Excluded" />
			<app-input-suggest v-model="model.alternate" store="groups" image="image" label="Link to Group" placeholder="Enter group name" v-if="model.exclude" />
			<app-input-tags v-model="model.terms" :dynamic-tag="model.title" label="Terms" />

		</app-page-content-section>

	</app-page-content>

	<app-page-foot />

</app-page>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, maxLength, minValue } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			model: {
				id: 0,
				slug: '',
				image: '',
				group: 0,
				text: '',
				title: '',
				visible: 1,
				exclude: 0,
				alternate: 0,
				terms: [],
				order: 0
			},
			param: 'item',
			noun: 'Item',
			store: 'items'
		}

	},

	validations: {
		model: {
			title: {
				required,
				maxLength: maxLength(64)
			},
			slug: {
				required
			},
			group: {
				required,
				minValue: minValue(1)
			},
			image: {
				required
			}
		}
	},

	methods: {

		goBackAfterSave: function() {

			this.clearStash()

			this.$router.push({
				name: 'Group.Items',
				params: {
					group: this.model.group
				}
			})

		}

	}

}

</script>

<style scoped>

</style>
